import { useEffect, useState } from "react";

export function LeaveScreen({ setIsMeetingLeft }) {
  const [isGuest, setIsGuest] = useState(false);
  const getUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const _meetingID = searchParams.get("meetingID");
    const room_id = searchParams.get("room_id");
    const _user = searchParams.get("user");
    if (_meetingID !== null || _user !== null || room_id !== null) {
      setIsGuest(true);
    }
  };
  useEffect(() => {
    getUrl();
  }, []);
  return (
    <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center">
      <h1 className="text-white text-4xl">You left the meeting!</h1>
      {/* <h4 className="text-white text-2xl mt-12">You may close this window</h4> */}
      <div className="mt-12">
        {!isGuest ? (
          <button
            className="`w-full bg-purple-350 text-white px-16 py-3 rounded-lg text-sm"
            onClick={() => {
              let user_pid = localStorage.getItem("user_pid");
              let cons_pid = localStorage.getItem("cons_pid");
              let org_pid = localStorage.getItem("org_pid");
              let pat_pid = localStorage.getItem("pat_pid");
              let user_role = localStorage.getItem("user_role");
              // setIsMeetingLeft(false);
              window.location.href = `${process.env.REACT_APP_FRONTEND}/doctor-my-consultation-completed?cons_pid=${cons_pid}&org_pid=${org_pid}&user_pid=${user_pid}&user_role=${user_role}&pat_pid=${pat_pid}`;
            }}
          >
            Back to Dashboard
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
