import React, { useEffect, useState } from "react";
import { Constants, MeetingProvider } from "@videosdk.live/react-sdk";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { MeetingAppProvider } from "./MeetingAppContextDef";
import WaitingToJoinScreen from "./components/screens/WaitingToJoinScreen";
// import {  useLocation} from 'react-router-dom';
import axios from "axios";

const App = () => {
  // const location = useLocation();
  const [token, setToken] = useState(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiIwZmRmZDI4OS00ZjdiLTQ1MjMtOTVlZS03ZDk2M2M1YWExM2EiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY4MjYwMzY3NywiZXhwIjoxODQwMzkxNjc3fQ.io5S4EsD9MLHHR227dJnZ_k62moS4NJsyn02hPzuDog"
  );
  // const [token, setToken] = useState("");
  // const [meetingId, setMeetingId] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(false);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [isGuestJoining, setIsGuestJoining] = useState(false);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  const getUrlParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const cons_pid = searchParams.get("cons_pid");
    const org_pid = searchParams.get("org_pid");
    const user_pid = searchParams.get("user_pid");
    const user_role = searchParams.get("user_role");
    const pat_pid = searchParams.get("pat_pid");

    if (
      cons_pid == null ||
      org_pid == null ||
      user_pid == null ||
      user_role == null ||
      pat_pid == null
    ) {
      return null;
    }
    let params = {
      cons_pid: parseInt(cons_pid),
      org_pid: parseInt(org_pid),
      user_pid: parseInt(user_pid),
      pat_pid: parseInt(pat_pid),
      user_role: parseInt(user_role),
    };
    localStorage.setItem("cons_pid", cons_pid);
    localStorage.setItem("org_pid", org_pid);
    localStorage.setItem("user_pid", user_pid);
    localStorage.setItem("user_role", user_role);
    localStorage.setItem("pat_pid", pat_pid);
    return params;
  };

  const getMeetingDetails = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const _meetingID = searchParams.get("meetingID");
    const room_id = searchParams.get("room_id");
    const ward_name = searchParams.get("ward_name");
    const user_role = searchParams.get("user_role");
    console.log(_meetingID);
    if (_meetingID !== null) {
      setMeetingId(_meetingID);
      setIsGuestJoining(true);
      return;
    }
    console.log(room_id);

    if (room_id !== null) {
      setMeetingId(room_id);
      localStorage.setItem("user_role", user_role);
      setParticipantName(ward_name);
      setMeetingStarted(true);
      return;
    }

    const headers = {
      "Content-Type": "application/json",
    };

    const data = getUrlParams();
    console.log(data);
    if (data == null) {
      console.log("Query Parameters not valid");
      return;
    }
    console.log(data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN}cons/get_data/`,
        data,
        {
          headers: headers,
        }
      );
      console.log(response.data);
      if (response.status == 200) {
        setMeetingId(response.data.data.consultations.room_id);
        setParticipantName(response.data.data.user_details.user_name);
        setMeetingStarted(true);
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
    getMeetingDetails();
  }, [isMobile]);

  return (
    <>
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",
              mode: meetingMode,
              multiStream: true,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                setToken("");
                setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
      ) : isGuestJoining ? (
        <JoiningScreen
          participantName={participantName}
          setParticipantName={setParticipantName}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={() => {
            setMeetingStarted(true);
          }}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
          meetingMode={meetingMode}
          setMeetingMode={setMeetingMode}
        />
      ) : (
        <WaitingToJoinScreen />
      )}
    </>
  );
};

export default App;
